@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");


* {
  margin: 0;
  padding: 0;
  color: inherit;
  box-sizing: border-box;
  font-family: sans-serif;
  text-decoration: inherit;
  line-height: 1.25rem;
}
li,
ul {
  list-style: none;
}
.spin {
  animation: spin 1s infinite;
  animation-timing-function: ease-in-out;
}
a{
  color: inherit !important;

}
h2,h3 {
  font-size: 1.3em !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fadein {
  animation: fade 0.2s ease;
  animation-fill-mode: both;
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes squeeze {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
@keyframes stretcher {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

a,
button {
  cursor: pointer;
}
button:disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}
hr {
  border: none;
  border-bottom: 1px solid $cor-cinza-medio;
}
.warning {
  background-color: pink !important;
}
.disable {
  display: none !important;
}
.invisible {
  opacity: 0;
}
.hide {
  visibility: hidden;
}
.body {
  background-color: $cor-base;
  background-image: url("../assets/fundolosango.png");
  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden;
  min-height: 100vh;
  color: $cor-base-txt;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

//perfilMobile------------------------------------------------------

.mobile__body {
  @extend .body;
  width: 100%;
  background-color: #eaeaea;
  background-image: unset;
  color: black;
}
.text_link {
  background: none;
  border: none;
  text-decoration: underline;
}
//perfilMobile------------------------------------------------------

.login__logo {
  width: 100%;
  max-width: 150px;
  cursor: pointer;
  pointer-events: fill;
  transition: 0.2s;
}
.app_header {
  align-self: flex-end;
  display: flex;
  justify-content: center;
  padding: 2em 2em 0;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.Login__navi {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  transition: 1s;
  gap: 0.5em;
}
.Login__icon {
  font-size: 2em;
  color: $cor-base-txt;
  padding: 10px;
  box-sizing: content-box;
  cursor: pointer;
  pointer-events: fill;
  filter: drop-shadow(0px 0px 5px black);
}
.Login__icon-neo {
  @extend .Login__icon;
  height: 1.5em;
  align-self: center;
  padding: 0;
}
.Login__icon--menu {
  margin-right: 20px;
  font-size: 1.2em;
}

.PopUp__icon {
  font-size: 3.5em;
  color: $cor-base-txt;
  margin: 20px;
}
.login__form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
}
.form__login {
  max-width: $conteiner-form-width;
  width: 100%;
  padding: 30px;
  text-align: center;
  margin-top: 45px;
}
.form__input {
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  color: black;
  $cor-desable-txt: #707070;
  background-color: white;
  border-bottom: 1px solid black;
}
.form__input::placeholder {
  color: $cor-desable-txt;
}
.form__btn {
  border: none;
  background-color: $cor-principal;
  border-radius: 5px;
  min-height: 2em;
  width: 100%;
  margin: 10px 0;
  color: $cor-base-txt;
  font-size: 1em;
  cursor: pointer;
}
.form__btn:hover {
  border: none;
  background-color: $cor-secundary;
  color: #fff;
}
.form__btn--secundary {
  @extend .form__btn;
  width: 100%;
}
.form__ou {
  display: flex;
  color: $cor-desable-txt;
  margin: 30px 0;
}
.form__ou hr {
  width: 100%;
  height: 1px;
  align-self: center;
  border: 1px solid $cor-desable-txt;
}
.form__ou p {
  padding: 5px;
}
.form__txt {
  align-items: center;
  margin: 15px 0;
  font-size: 0.9em;
}
.form__popup {
  box-sizing: border-box;
  position: absolute;
  background-color: $cor-base;
  border: 1px solid $cor-desable-txt;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup__background {
  background-color: $cor-fundo-tranp;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.popup__response {
  display: flex;
  gap: 1em;
  width: 100%;
}

.popup__title {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}
.popup__buttonsContainer {
  display: flex;
  width: 100%;
  gap: 10px;
}

.home__neolist {
  max-width: 700px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3em 0;
}

.home__neocard {
  min-width: 200px;
  min-height: 200px;
  border: none;
  margin: 5px;
  position: relative;
  opacity: 0;

  animation: fade 0.2s ease;
  animation-fill-mode: both;
}

.header__content {
  margin-top: 5em;
}
.home__neolabel {
  color: $cor-base-txt;
  background-color: $cor-fundo-tranp;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 10px;
}
.home__search {
  // opacity: 0;
  transition: opacity 0.2s ease-in-out;
  font-size: 1.2em;
  outline: none;
  margin: 0;
}
.search_container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.search_clearButton {
  position: absolute;
  background: none;
  border: none;
  right: 0;
  transition: opacity 0.2s ease-in-out;
  vertical-align: middle;
  opacity: 0.5;
  padding: 10px;
  outline: none;
  font-size: 16px;
  font-weight: bolder;
}

.search_clearButton:hover {
  opacity: 1;
  cursor: pointer;
}
.search_clearButton:active {
  opacity: 0.5;
}
.input__slots {
  padding: 10px 0;
  max-width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;

  margin: auto;
}
.input__slots input {
  text-align: center;
}

.iframeFull {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iframeFull iframe {
  border: none;
}
.iframeMenu {
  position: absolute;
  width: 100%;
  pointer-events: none;
}
.page__content {
  max-width: $conteiner-form-width;
  width: 100%;
  padding-bottom: 30px;
  text-align: center;
  margin: auto;
}
.page_placement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.page_placement-center {
  @extend .page_placement;
  justify-content: center;
}
.page_placement-top {
  @extend .page_placement;
  justify-content: end;
  padding-top: 5em;
  width: 100%;
}
.aviso__text {
  padding: 30px 0;
}
.header__menu {
  background-color: $cor-fundo-tranp;
  width: 100%;
  height: 100%;
}
.menu__content {
  background-color: #fff;
  width: 100%;
  max-width: 300px;
  color: $cor-desable-txt;
  height: 100%;
  pointer-events: all;
}
.header__menu--content {
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  display: flex;

  animation: fade ease 0.2s;
  animation-fill-mode: both;
}
.menu__titulo {
  display: grid;
  grid-template-columns: 5fr 1fr;
  padding: 10px;
  text-align: center;
  margin: 10px 0;
  background-color: $cor-cinza-medio;
  font-size: 1.2em;
}
.menu__titulo span {
  cursor: pointer;
}
.menu__titulo span:hover {
  opacity: 0.7;
}
.menu__lista li {
  cursor: pointer;
  padding: 20px;
}
.menu__lista li:hover {
  opacity: 0.7;
}
.page__placement{
  overflow: auto;
  width: 100%;
}
.page__placement--perfil {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.file__input{
  display: flex;
  gap:1em;
  align-content:center;
}
.file__a {
  background: #e46201;
  color: white !important;
  padding: 0.4em 1em;
  border-bottom: 1px solid $cor-fundo-tranp;
  border-radius: 5px;
  text-align: center;
  line-height: 14px;
  font-size: 14px;
  transition: background 200ms;
  height: 48px;
  display: flex;
  align-items: center;
}
.file__a:hover {
  background: #f6f6f6;
}

  
.page__content--perfil {
  max-width: 500px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 0 30px;
  text-align: center;
}
.perfil__celular {
  display: grid;
  grid-template-columns: 1fr 6fr;
  gap: 10px;
}

.popup__message {
  padding: 20px;
  font-size: 0.8em;
}
.page__content--mensagemperfil {
  max-width: 500px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 0 30px;
  text-align: center;
  margin-top: 30%;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .Login__icon {
    font-size: 1.5em;
    margin: 0;
  }
  .searcg__mobile {
    position: absolute;
    top: 0;
    left: 0;
  }
  .home__neolist {
    padding-top: 0;
  }
  .app_header {
    padding: 1em;
  }
  .notsearch {
    font-size: 1em;
    animation: squeeze ease 0.2s;
    animation-fill-mode: end;
  }
  .notsearchopen {
    font-size: 1em;
    animation: stretcher ease 0.2s;
    animation-fill-mode: end;
  }
  .login__logo {
    margin: auto;
  }
  .vanish_element {
    display: none;
  }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active{
  display: flex !important;
  justify-content: center;
  height: 100%;
  object-fit: contain;
}

.neo_carrossel  {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

 .carousel {
   width: 100%;
   height: 70vh;
   position: unset;
 }
 .carousel-inner{
  height: 100%;
  width: 80%;
  margin: auto;
 }
 img{
  height: 70%;
  padding: 2rem;
  object-fit: contain;
  cursor: pointer;
  z-index: 3;

 }
 .active{
  height: 100%;
 }
 a {
   opacity: 0 !important;
   width: 50%;
 }
 .carrossel_unactive-prev{
   position: absolute;
   height: 50%;
   padding: 2em 0;
   object-fit: cover;
   object-position: left;
   left: 10%;
   opacity: .5;
 }
 .carrossel_unactive-next{
    position: absolute;
    height: 50%;
    padding: 2em 0;
    object-fit: cover;
    object-position: left;
    right: 10%;
    opacity: .5;
  }

}


.carrossel_img {
  max-width: 500px;
  object-fit: cover !important;
  cursor: pointer;
}
@media only screen and (max-width: 500px) {
  .carrossel_unactive-prev, .carrossel_unactive-next{
    display: none;
  }
  .neo_carrossel img{
    width: 100%;
   }
 
}
.slick-dots {
  display: flex !important;
  justify-content: center;
  gap: 10px;
  margin-top: 2em;
}
.slick-dots li button:before {
  font-size: 15px;
}
.neo_content {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.content_video {
  width: 100%;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  overflow: hidden;
}
.neo_content iframe {
  border: none;
}
.content_contato {
  width: 100%;
  max-width: 700px;
  padding: 2em;
  border-radius: 8px;
  margin: 1em;
  box-shadow: 5px 5px 25px #00000073;
  text-align: center;
}
.contato_titulo {
  margin-bottom: 1em;
}
.contato_text {
  margin-bottom: 1em;
}
.content_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.form__loading {
  position: fixed;
}
.outlet_header {
  display: flex;
}
.content_container {
  display: flex;
  padding: 1em;
  flex-direction: column;
  width: 95vw;
}
.content_featured {
  display: flex;
  min-height: 60vh;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
}
// .content_featured:hover {
//   .featured_image {
//     opacity: 0.8;
//   }
//   .info_featured {
//     transform-origin: center;
//     transform: scale(1.05);
//   }
// }
// .content_featured:active {
//   .featured_image {
//     opacity: 1;
//   }
// }
.featured_image {
  width: 50%;
  height: 100%;
  transition: 0.2s;
  position: absolute;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  -webkit-mask-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: left top, left bottom;
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  mask-repeat: no-repeat;
  mask-position: left top, left bottom;
}
.neo_content-outlet {
  @extend .neo_content;
  flex-direction: column;
  justify-content: flex-start;
}
.content_title {
  margin-bottom: 1em;
}
.content_slider {
  overflow: hidden;
  display: flex;
}
.content_slider-list {
  display: inline-flex;
  gap: 1em;
}
.seccion_conainer {
  position: relative;
}

.content_session {
  padding: 1em;
}
.content_img {
  height: 150px;
  min-width: 150px;
  position: relative;
}

.content_imgBuy {
  @extend .content_img;
  filter: brightness(50%);
}

.content_iconBuy {
  font-size: 2em;
}

.content_item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.content_item:hover {
  opacity: .5;
}

.content_textBuy {
  background-color: black;
  padding: 5px 15px;
  border-radius: 20px;
  font-weight: bold;
}
.content_containerBuy {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.507);
}
.Slider_container {
  position: absolute;
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  pointer-events: none;
}
.slider_button {
  padding: 10px;
  border: none;
  opacity: 0.8;
  outline: none;
  pointer-events: fill;
}
.slider_button:hover {
  opacity: 1;
}
.loading_container {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9999999999;
}
.loading_icon {
  font-size: 3em;
  color: white;
}
.content_audio {
  background-color: black;
  height: 100%;
  color: white;
  overflow: auto;
}
.audioplayer_topo {
  min-height: 18em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.audio_capa {
  text-align: center;
}
.audio_capa img {
  padding: 2em;
  max-width: 250px;
  width: 100%;
}
.audio_playlist {
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  padding: 1.2em;
  gap: 10px;
  user-select: none;
}
.playlist_title {
  display: flex;
  justify-content: center;
  padding: 1em;
}
.audio_playlist li {
  opacity: 0.5;
  cursor: pointer;
  line-height: 1.8em;
  position: relative;
}
.audio_playlist li:hover {
  opacity: 1;
}
.audio_playlist li:active {
  opacity: 0.5;
}
.neo_container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.login__body {
  height: 100vh;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.neo_content-Documento {
  overflow: auto;
  min-height: 100vh;
}
li.playlist_currentSong {
  opacity: 1;
}
div.playlist_currentSong {
  content: 0;
  position: absolute;
  left: -2em;
  top: 0;
  background: red;
  border-radius: 50%;
  margin: 10px;
  height: 0.5em;
  width: 0.5em;

  box-shadow: 0 0 0 0 rgb(255, 0, 1);
  transform: scale(1);
  animation: pulse 1s infinite;
}
.audioplayer_grandient {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}
.audioplayer_top {
  position: relative;
  min-height: 18em;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(255, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
@media only screen and (max-width: 800px) {
  .audio_playlist li {
    font-size: 0.8em;
  }
}
.neo_openingVideo {
  background-color: black;
  position: fixed;
  right: 0;
  z-index: 1000;
  width: 100%;
}
.neo_openingVideo_close {
  position: absolute;
  font-size: 1em;
  font-weight: bolder;
  right: 2em;
  top: 2em;
  z-index: 1000;
  color: black;
  width: 1.7em;
  height: 1.7em;
  border: none;
  outline: none;
}
.neo_openingVideo_close:hover {
  opacity: 0.5;
}
.neo_openingVideo_close:active {
  opacity: 1;
}
.neo_openingThumb {
  position: absolute;
  top: 1em;
  left: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}
.neo_openingThumb:hover {
  opacity: 0.5;
}
.neo_openingThumb:active {
  opacity: 1;
}
.neo_openingIcon {
  position: absolute;
  font-size: 2em;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.5);
}
.file_content {
  width: 100%;
  height: 100%;
  padding: 7em 0 1em;
  max-width: 700px;
}
.content_info {
  position: absolute;
  font-size: 14px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 100%;
  padding: 0.5em;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  max-width: 100%;
}

.content_titleBar {
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.content_descriptionBar {
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
}
.perfil_page {
  height: 100%;
  width: 100%;
  padding: 2em;
  overflow: auto;
}
.Perfil_headerIcon {
  font-size: 3.5em;
  color: #fff;
  margin-bottom: 20px;
}

.form__inputReduzido {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  padding: 5px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid;
  margin: 10px 0;
  position: relative;
  outline: none;
}

.form__inputReduzido:disabled {
  width: 100%;
  font-family: "Montserrat", sans-serif;

  padding: 5px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid;
  opacity: 0.3;
}
.form__containerReduzido {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: baseline;
  width: 100%;
  max-width: 750px;
}
@media only screen and (max-width: 450px) {
  .form__containerReduzido {
    grid-template-columns: 1fr;
  }
}
.form_backgroundColor {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3em 20px;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.button__perfilReduzido {
  font-family: "Montserrat", sans-serif;
  margin: 20px auto;
  width: 100%;
  font-weight: 700;
  padding: 3px;
  border-radius: 20px;
  background-color: #f18e17;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border: none;
  color: #fff;
  cursor: pointer;
  height: 2em;
  max-width: 400px;
}
.button__perfilReduzido:hover {
  opacity: 0.5;
}
.button__perfilReduzido:active {
  opacity: 1;
}
.body__conteinerReduzido {
  font-family: "Montserrat", sans-serif;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  margin-top: -20px;
  background-position: 50%;
  overflow-x: auto;
  text-align: center;
  display: flex;
}
.form__perfilLogo {
  max-width: 10em;
  margin-bottom: 2em;
}
.button__perfilReduzido-link {
  color: white;
  font-weight: bold;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  outline: none;
}
.button__perfilReduzido-link:hover {
  opacity: 0.5;
}
.button__perfilReduzido-link:active {
  opacity: 1;
}
.perfil_listaProdutos li {
  list-style: disc;
}
.perfil_listaProdutos {
  text-align: left;
  margin-left: 30px;
}
.form__popup h3 {
  margin: 10px 0;
}
.perfil_comprarAssinaturas {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}
.perfil_comprarAssinaturas li img {
  width: 100%;
  cursor: pointer;
  border: solid 1px;
}
.perfil_comprarAssinaturas li img:hover {
  opacity: 0.5;
}
.perfil_comprarAssinaturas li img:active {
  opacity: 1;
}
.contact_icon {
  margin: 0 15px;
}
.perfil_container {
  width: 100%;
  max-width: 750px;
}
.perfil_container h3 {
  font-size: 1em;
  margin: 10px 0;
}
.perfil_contacts {
  text-align: left;
  margin: 5px 0;
}

.perfil_contacts a:hover {
  opacity: 0.5;
}
.perfil_contacts a:active {
  opacity: 1;
}
.perfil_page.joyceLayout {
  width: 100%;
  padding: 0;
  max-width: unset;
  height: 100vh;
  display: flex;
  align-items: center;
}
.page__content.joyceLayout {
  width: 100%;
  max-width: unset;
  display: flex;
  flex-direction: column;
  padding: 2em;
  align-items: center;
}
.form__input.joyceLayout {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  padding: 5px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid;
  text-align: center;
}
.phone_input{
  display: grid;
  grid-template-columns: 5em 1fr;
  gap:1em;
}
[type=radio].joyceLayout{
  width: 1em;
  position: relative;
}
[type=radio].warning::before{
  content: "*";
  //width: 10%;
  position: relative;
  //height: 10%;
  right: 2px;
  padding: 0 30%;
  color: transparent;
  border-radius: 50%;
  border: 2px solid #ffb6c157 !important;
  background-color: #ffb6c157 !important;
}
[type=checkbox].warning::before{
  content: "*";
  position: relative;
  right: 1px;
  padding: 0 25%;
  color: transparent;
  border-radius: 50%;
  border: 2px solid #ffb6c157 !important;
  background-color: #ffb6c157 !important;
}
[type=file].warning::before {
  content: "*";
  position: relative;
  color: transparent;
  background-color: #ffb6c157 !important;
}

.perfil_inputContainer.joyceLayout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  max-width: 750px;
}
.perfil_inputContainer.joyceLayout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  max-width: 750px;
}

.form__btn--secundary.joyceLayout {
  font-family: "Montserrat", sans-serif;
  margin: unset;
  width: 100%;
  font-weight: 700;
  padding: 3px;
  border-radius: 20px;
  transition: 0.2s;
  border: none;
  cursor: pointer;
  max-width: 400px;
  outline: none;
}
.form__btn--secundary.joyceLayout:hover {
  opacity: 0.5;
}
.form__btn--secundary.joyceLayout:active {
  opacity: 1;
}
.form__btn--secundary.products.joyceLayout {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  width: 100%;
  font-weight: 700;
  padding: 3px;
  border-radius: 20px;
  transition: 0.2s;
  border: none;
  cursor: pointer;
  max-width: 400px;
  background-color: unset;
  text-decoration: underline;
}
.form__btn--secundary.products.joyceLayout:hover {
  background-color: unset;
}
.popup__response.joyceLayout {
  padding: 1em;
  justify-content: center;
  align-content: center;
}
.info_featured {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 3em;
  padding-right: 0;
  padding-bottom: 1em;
  gap: 1em;
  transition: 0.2s;
  z-index: 3;

}
.vanish_element {
  opacity: 0;
}

@media only screen and (max-width: 600px) {
  .perfil_inputContainer.joyceLayout {
    grid-template-columns: 1fr;
  }
  .info_featured {
    padding-bottom: 4rem;
    width: 100%;
  }
  .featured_image {
    -webkit-mask-image: linear-gradient(to top, black, rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to top, black, rgba(0, 0, 0, 0));
    position: absolute;
    height: 60%;
    bottom: 0;
    width: 100%;
  }
  .outlet_header {
    display: flex;
    flex-direction: column;
    min-height: unset;
  }
}
.slide {
  transform: scale(0.7);
  transition: transform 300ms;
  opacity: 0.5;
}

.slideWrapper {
  display: flex;
  justify-content: center;
}

.prevArrow {
  position: absolute;
  top: 40%;
  left: 60px;
  z-index: 100;
  cursor: pointer;
  font-size: 2rem;
}

.nextArrow {
  position: absolute;
  top: 40%;
  right: 60px;
  z-index: 100;
  cursor: pointer;
  font-size: 2rem;
}
.slick-list {
  max-height: 80vh;
  width: 90vw;
}
.slideWrapper{
  height: 80vh;
}
.options_group{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
}
.options_item{
  display:flex;
  gap: 5px;
  align-items: baseline;
  input {
      width: 1em;
  }
  label{
      white-space:nowrap;
  }
}
.perfil_inputContainer{
  label{
    text-align: left;
    p {
      margin-bottom: 5px;

    }
  }
}
// confirm logout box-----------------------------
.react-confirm-alert-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.react-confirm-alert {
  box-sizing: border-box;
  position: absolute;
  background-color: #cccccc;
  border: 1px solid $cor-desable-txt;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
// dialog logout styles--------------------------------
.add-dialog p {
  color: white;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.add-dialog-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px
}
.add-dialog-buttons button{
  border: none;
  background-color: #FE7917;
  border-radius: 5px;
  min-height: 2em;
  width: 100%;
  margin: 10px 0;
  color: $cor-base-txt;
  font-size: 1em;
  cursor: pointer;
}

.add-dialog-buttons button:hover {
  border: none;
  background-color: #e46201;
  color: #fff;
}


